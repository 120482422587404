import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Element } from "react-scroll";
import styled, { css } from "styled-components";

import { BoldColor, LightBackground, White } from "../constants/colors";
import Banner from "./Banner";
import Description from "./common/Description";
import Header2 from "./common/Header2";

import { media } from "../utility/mediaQuery";
import { Dove } from "./Icons";
import Posts from "../constants/posts";

const Story = () => {
  return (
    <Element name="story">
      <Container>
        <Banner title="Portfolio" />
        {Posts.map((post, index) => (
          <Wrapper isEven={index % 2 === 0} key={post.title}>
            <ImageWrapper isEven={index % 2 === 0}>
              <Carousel
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
                showThumbs={false}
                useKeyboardArrows={false}
                autoPlay={true}
                stopOnHover={true}
                showIndicators={false}
                interval={4000}
              >
                {post.imageUrl.map((image) => (
                  <div key={image}>
                    <img src={image} alt="Image1" />
                  </div>
                ))}
              </Carousel>
            </ImageWrapper>
            <InfoWrapper isEven={index % 2 === 0} isFirst={index === 0}>
              <Header2>{post.title}</Header2>
              <Description>{post.description}</Description>
              <HeartWrapper className="heart_wrapper">
                <StyledHeart />
              </HeartWrapper>
            </InfoWrapper>
          </Wrapper>
        ))}
      </Container>
    </Element>
  );
};

const Container = styled.div`
  padding: 60px 15px 0;
  max-width: 1024px;
  margin: auto;
`;

const Wrapper = styled.div<{
  isEven: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${media.sm`
    flex-direction: row;
  `}

  ${({ isEven }) =>
    isEven &&
    css`
      flex-direction: column-reverse;
      ${media.sm`
        flex-direction: row-reverse;
      `}
    `}

  &:not(:first-child) {
    margin-top: 25px;
    ${media.sm`
      margin-top: 0;
    `}
  }
`;

const HeartWrapper = styled.div`
  position: absolute;
  width: 44px;
  height: 44px;
  background-color: ${BoldColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${BoldColor};
  top: -22px;
  opacity: 0;
`;

const ImageWrapper = styled.div<{
  isEven: boolean;
}>`
  ${media.sm`
    flex: 1 0 50%;
    padding-bottom: 100px;
  `}

  ${({ isEven }) =>
    isEven
      ? css`
          ${media.sm`
            flex: 1 0 49.75%;
            padding-left: 45px;
          `}
        `
      : css`
          ${media.sm`
            padding-right: 45px;
          `}
        `}
`;

const InfoWrapper = styled.div<{
  isEven: boolean;
  isFirst: boolean;
}>`
  padding: 40px 25px;
  background-color: ${LightBackground};
  position: relative;

  ${media.sm`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${White};
  `}

  ${({ isEven }) =>
    isEven
      ? css`
          ${media.sm`
            padding-right: 45px;
            text-align: right;
            border-right: 1px solid ${BoldColor};
            & .heart_wrapper {
              right: -22px;
            }
          `}
        `
      : css`
          ${media.sm`
            padding-left: 45px;
            border-left: 1px solid ${BoldColor};
            & .heart_wrapper {
              left: -22px;
            }
          `}
        `}
  
  ${({ isFirst }) =>
    !isFirst &&
    css`
      ${media.sm`
        border-top: 1px solid ${BoldColor};

        & .heart_wrapper {
          opacity: 1;
        }
      `}
    `}
`;

const StyledHeart = styled(Dove)`
  width: 22px;
  height: 22px;

  & path {
    fill: ${White};
  }
`;

export default Story;
