import React from "react";
import styled, { css } from "styled-components";
import { Element } from "react-scroll";

import { LightBackground } from "../constants/colors";
import Description from "./common/Description";
import Header2 from "./common/Header2";
import Signature from "./common/Signature";
import { media } from "../utility/mediaQuery";

const Meghana = "/media/meghana.jpg";

const Overview = () => {
  const people = [
    {
      imageUrl: Meghana,
      title: "A Little Intro",
      description:
        "My name is Meghana Sardesai and I'm a quilling enthusiast. Since the time I was in school, I was always keen on learning new craft skills. Quilling is something that I stumbled upon in 2015 and since then there was no looking back. Right from experimenting with different tools to paper, this art form taught me patience and gave me immense happiness. I hail from a small laid back city in India, called Belgaum. This is where I grew up and completed my undergraduate studies. I moved to a bigger city in 2014 to explore my options and began this roller coaster of a ride while trying to learn and grow personally and professionally. An analyst by choice and an artist by heart, I love what I do. Besides art, I love traveling, going on treks and enjoy adventure sports. Sports and art have been a major part of my life and I will cherish that forever.",
      signature: "Meghana",
      isEven: false,
    },
  ];
  return (
    <Element name="overview">
      <Container>
        {people.map((person) => (
          <Wrapper isEven={person.isEven} key={person.title}>
            <ImageWrapper>
              <img src={person.imageUrl} alt={person.title} />
            </ImageWrapper>
            <InfoWrapper>
              <Header2>{person.title}</Header2>
              <Description>{person.description}</Description>
              <Signature>{person.signature}</Signature>
            </InfoWrapper>
          </Wrapper>
        ))}
      </Container>
    </Element>
  );
};

const Container = styled.div`
  padding: 60px 15px;
  max-width: 1024px;
  margin: auto;
`;

const Wrapper = styled.div<{
  isEven: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${media.sm`
    flex-direction: row;
  `}

  ${({ isEven }) =>
    isEven &&
    css`
      flex-direction: column-reverse;
      ${media.sm`
        flex-direction: row-reverse;
      `}
    `}

  &:not(:first-child) {
    margin-top: 25px;
    ${media.sm`
      margin-top: 0;
    `}
  }
`;

const ImageWrapper = styled.div`
  ${media.sm`
    flex: 1 0 50%;
  `}
`;

const InfoWrapper = styled.div`
  padding: 40px 25px;
  background-color: ${LightBackground};
  ${media.sm`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

export default Overview;
