import styled from "styled-components";
import { LightBlack } from "../../constants/colors";
import { media } from "../../utility/mediaQuery";

const Description = styled.div`
  margin-top: 14px;
  color: ${LightBlack};
  font-size: 15px;
  line-height: 25px;

  ${media.md`
    line-height: 28px;
    font-size: 17px;
  `}
`;

export default Description;
