export const LightGrey = "#ececec";
export const DarkGrey = "#999";
export const Blue = "#145fa7";

export const ErrorBorder = "#ff3f79";
export const ErrorBackground = "#ffdce6";
export const ErrorText = "#a94442";

export const White = "#FFFFFF";
export const Black = "#3d3d3d";
export const ExtraLightBlack = "#a9a9a9";
export const LightBackground = "#F5F4F0";
export const BoldColor = "#F35A59";
export const LightBlack = "#767878";
