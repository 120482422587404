import React from "react";
import styled from "styled-components";
import { BoldColor, LightBackground } from "../constants/colors";
import { media } from "../utility/mediaQuery";

const cat = "/media/cat.png";

interface Props {
  title: string;
  hideStand?: boolean;
}

const Banner = ({ title, hideStand }: Props) => {
  return (
    <Header>
      {!hideStand && (
        <>
          <Cat>
            <img src={cat} />
          </Cat>
          <Stand></Stand>
        </>
      )}
      <SectionTitle>{title}</SectionTitle>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const Stand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 120px;
    height: 1px;
    background-color: ${BoldColor};
    ${media.md`
      width: 120px;
    `}
  }
  &::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 30px;
    background-color: ${BoldColor};
    ${media.md`
      height: 30px;
    `}
  }
`;

const SectionTitle = styled.div`
  padding: 8px 10px 3px;
  font-size: 30px;
  background-color: ${LightBackground};
  border: 1px solid ${BoldColor};
  border-width: 1px 0;
  line-height: 33px;
  color: ${BoldColor};

  ${media.md`
    font-size: 40px;
    line-height: 44px;
    padding: 10px 25px 5px;
  `}
`;

const Cat = styled.div`
  width: 150px;
`;

export default Banner;
