import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Element } from "react-scroll";

import { White } from "../constants/colors";
import { media } from "../utility/mediaQuery";

interface Props {
  imageUrl: string;
  title?: string;
  subTitle?: string;
  actionButton?: ReactNode;
}

const Cover = ({ imageUrl, title, subTitle, actionButton }: Props) => {
  return (
    <Element name="home">
      <Container>
        <Wrapper cover={imageUrl} />
        <TextContainer>
          <TextWrapper>
            {title && <Title>{title}</Title>}
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </TextWrapper>
        </TextContainer>
        {actionButton}
      </Container>
    </Element>
  );
};

const Container = styled.div`
  position: relative;
  height: calc(100vh - 92px);
  overflow: hidden;
`;

const Wrapper = styled.div<{
  cover: string;
}>`
  ${({ cover }) => css`
    background: url(${cover}) center center/cover no-repeat local;
  `}
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Title = styled.h3`
  font-size: 55px;
  font-weight: 500;
  line-height: 60px;
  color: ${White};

  ${media.md`
    font-size: 70px;
  `}
`;

const SubTitle = styled.h4`
  font-size: 17px;
  font-weight: 100;
  color: ${White};
  text-transform: uppercase;
  letter-spacing: 5px;
  padding: 8px 14px;
  margin-top: 15px;
  border: 1px solid ${White};
  border-width: 1px 0;

  ${media.md`
    font-size: 20px;
    margin-top: 25px;
  `}
`;

const TextWrapper = styled.div`
  text-align: center;
  padding: 0 10px;
`;

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Cover;
