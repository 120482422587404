import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-scroll";

import { BoldColor, LightGrey } from "../constants/colors";
import { media } from "../utility/mediaQuery";
import MenuSlide from "./MenuSlide";
import { LinkMap } from "../constants/links";

const logo = "/media/logo.png";

interface Props {
  heightOffset: number;
  hideTabs?: boolean;
}

const Header = ({ heightOffset, hideTabs = false }: Props) => {
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const isNewSticky =
      document.documentElement.scrollTop > window.innerHeight - heightOffset;
    if (isNewSticky !== isSticky) {
      setSticky(isNewSticky);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, [isSticky, heightOffset]);

  return (
    <>
      <Container isSticky={isSticky}>
        <Wrapper>
          <Logo>
            <img src={logo} />
          </Logo>
          {!hideTabs && (
            <>
              <MenuSlideWrapper>
                <MenuSlide />
              </MenuSlideWrapper>
              <LeftNav>
                {LinkMap.map((link) =>
                  link.href === window.location.pathname ? (
                    <Link
                      key={link.title}
                      to={link.to}
                      smooth={true}
                      duration={500}
                      offset={-92}
                    >
                      <NavScrollLink>{link.title}</NavScrollLink>
                    </Link>
                  ) : (
                    <NavLink key={link.title} href={link.href}>
                      {link.title}
                    </NavLink>
                  )
                )}
              </LeftNav>
            </>
          )}
        </Wrapper>
      </Container>
      <DummyHeader isSticky={isSticky} />
    </>
  );
};

const Container = styled.div<{
  isSticky: boolean;
}>`
  padding: 15px;
  border-bottom: 1px solid ${LightGrey};
  background-color: #fff;
  z-index: 2;

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    `}
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

const DummyHeader = styled.div<{
  isSticky: boolean;
}>`
  height: 0;

  ${({ isSticky }) =>
    isSticky &&
    css`
      height: 92px;
    `}
`;

const Logo = styled.div`
  width: 60px;
`;

const MenuSlideWrapper = styled.div`
  ${media.sm`
    display: none;
  `}
`;

const LeftNav = styled.div`
  display: none;
  align-items: center;
  margin-right: 10px;

  ${media.sm`
    display: inline-flex;
  `}
`;

const NavLinkCss = css`
  margin-left: 15px;
  color: ${BoldColor};
  cursor: pointer;

  ${media.md`
  font-size: 18px;
`}
`;

const NavScrollLink = styled.span`
  ${NavLinkCss}
`;

const NavLink = styled.a`
  ${NavLinkCss}
`;

export default Header;
