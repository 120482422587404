import styled from "styled-components";
import { Black } from "../../constants/colors";
import { media } from "../../utility/mediaQuery";

const Header2 = styled.h3`
  font-size: 20px;
  font-weight: 700;
  color: ${Black};
  text-transform: uppercase;

  ${media.md`
    font-size: 22px;
  `}
`;

export default Header2;
