import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Events, Link } from "react-scroll";
import { BoldColor, LightGrey, White } from "../constants/colors";
import { LinkMap } from "../constants/links";
import { Menu, MenuOpen } from "./Icons";

const MenuSlide = function () {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    Events.scrollEvent.register("end", function (to, element) {
      if (isActive) {
        setIsActive(false);
      }
    });

    return () => {
      Events.scrollEvent.remove("end");
    };
  }, [isActive]);

  return (
    <>
      <MenuIcon>
        <StyledMenu onClick={() => setIsActive(true)} />
      </MenuIcon>
      <Container isActive={isActive}>
        {isActive && (
          <IconWrapper>
            <StyledMenuOpen onClick={() => setIsActive(false)} />
          </IconWrapper>
        )}
        {LinkMap.map((link) =>
          link.href === window.location.pathname ? (
            <Link
              key={link.title}
              to={link.to}
              smooth={true}
              duration={500}
              offset={-92}
            >
              <NavScrollLink>{link.title}</NavScrollLink>
            </Link>
          ) : (
            <NavLink key={link.title} href={link.href}>
              {link.title}
            </NavLink>
          )
        )}
      </Container>
    </>
  );
};

const Container = styled.div<{
  isActive: boolean;
}>`
  position: fixed;
  height: 100vh;
  background-color: ${White};
  width: 0;
  z-index: 2;
  top: 0;
  right: 0;
  border-left: 1px solid ${LightGrey};
  border-radius: 5px 0 0 5px;
  transition: width 0.5s;

  ${({ isActive }) =>
    isActive &&
    css`
      width: 180px;
    `}
`;

const NavLinkCss = css`
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: ${BoldColor};
  border-bottom: 1px solid ${LightGrey};
  padding: 10px 15px 10px 35px;
`;

const NavScrollLink = styled.span`
  ${NavLinkCss}
`;

const NavLink = styled.a`
  ${NavLinkCss}
`;

const MenuIcon = styled.div`
  width: 40px;
  height: 35px;
  background-color: ${BoldColor};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
`;

const IconWrapper = styled.div`
  position: absolute;
  transform: scaleX(-1);
  top: 5px;
  left: -35px;
  background-color: ${White};
  padding: 5px;
  height: 35px;
  border: 1px solid ${LightGrey};
  border-left: 0;
  border-radius: 0 5px 5px 0;
`;

const StyledMenu = styled(Menu)`
  cursor: pointer;
  width: 24px;

  & path {
    fill: ${White};
  }
`;

const StyledMenuOpen = styled(MenuOpen)`
  cursor: pointer;
  width: 24px;

  & path {
    fill: ${BoldColor};
  }
`;

export default MenuSlide;
