import React, { forwardRef, Ref, SVGAttributes } from "react";

export const Room = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 24 24">
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
      </svg>
    );
  }
);

export const Clock = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 24 24">
        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
      </svg>
    );
  }
);

export const Heart = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 24 24">
        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
      </svg>
    );
  }
);

export const Close = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 24 24">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    );
  }
);

export const Menu = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 24 24">
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
      </svg>
    );
  }
);

export const MenuOpen = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 24 24">
        <path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z" />
      </svg>
    );
  }
);

export const Dove = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 191 167">
        <path
          d="M 0.00,27.75
           C 0.00,27.75 63.50,149.75 63.50,149.75
             63.50,149.75 132.75,166.50 132.75,166.50
             132.75,166.50 148.50,130.00 148.50,130.00
             148.50,130.00 180.50,119.25 180.50,119.25
             180.50,119.25 168.50,40.25 168.50,40.25
             168.50,40.25 190.50,34.00 190.50,34.00
             190.50,34.00 164.50,23.25 164.50,23.25
             164.50,23.25 146.50,33.75 146.50,33.75
             146.50,33.75 140.75,61.25 140.75,61.25
             140.75,61.25 105.25,0.25 105.25,0.25
             105.25,0.25 76.50,80.75 76.50,80.75
             76.50,80.75 0.00,27.75 0.00,27.75 Z"
        />
      </svg>
    );
  }
);

export const Rupee = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 98.85 122.88">
        <g>
          <path d="M0,48.23l8.38-18.88h31.61c-0.4-1.34-1.09-2.66-2.09-3.95c-1-1.29-2.23-2.4-3.69-3.35c-1.46-0.94-3.09-1.72-4.89-2.29 c-1.8-0.6-3.72-0.89-5.72-0.89H0L8.38,0h90.47l-8.38,18.88H68.62c0.86,0.66,1.66,1.43,2.4,2.32c0.74,0.86,1.4,1.75,1.97,2.72 c0.54,0.94,1.03,1.89,1.43,2.83c0.37,0.94,0.63,1.83,0.74,2.6h23.68l-8.38,18.88H73.34c-1.26,3.2-3.12,6.24-5.61,9.1 c-2.49,2.89-5.38,5.43-8.67,7.72c-3.32,2.29-6.89,4.18-10.76,5.75c-3.86,1.54-7.78,2.57-11.76,3.15l47.54,48.94H46.51L4.12,76.37 V59.04h18.76c1.8,0,3.58-0.29,5.32-0.89c1.77-0.6,3.4-1.4,4.86-2.4c1.49-0.97,2.77-2.15,3.86-3.43c1.09-1.32,1.86-2.66,2.32-4.09H0 L0,48.23z" />
        </g>
      </svg>
    );
  }
);

export const Add = forwardRef(
  (props: SVGAttributes<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
    return (
      <svg ref={ref} {...props} viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
      </svg>
    );
  }
);
