import React from "react";
import styled from "styled-components";
import { BoldColor, White } from "../constants/colors";

const CoverButton = () => {
  return (
    <Container>
      <Wrapper href="/covid-relief/fundraiser-2021">
        <TitleWrapper>
          <Title>View</Title>
          <SubTitle>Fundraiser 2021</SubTitle>
        </TitleWrapper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -170px;
  width: 100%;
  left: 0;
  z-index: 2;
`;

const Wrapper = styled.a`
  display: inline-block;
  width: 270px;
  height: 270px;
  border-radius: 50%;
  transition: 1s 2.3s ease-in-out;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${BoldColor};
    opacity: 0.7;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  cursor: pointer;
`;

const Title = styled.h5`
  line-height: 30px;
  font-size: 28px;
  color: ${White};
`;

const SubTitle = styled.h5`
  line-height: 30px;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: 1px;
  color: ${White};
`;

export default CoverButton;
