const Scar = "/media/portfolio/scar.jpg";
const Rooster = "/media/portfolio/rooster.jpg";
const Cat = "/media/portfolio/cat.jpg";
const Parrot = "/media/portfolio/parrot.jpg";
const Fish = "/media/portfolio/fish.jpg";
const Lilly = "/media/portfolio/lilly.jpg";
const Deadpool = "/media/portfolio/deadpool.jpg";
const Joker = "/media/portfolio/joker.jpg";
const Mojojojo = "/media/portfolio/mojojojo.jpg";
const Leaf = "/media/portfolio/leaf.jpg";
const Pumpkin = "/media/portfolio/pumpkin.jpg";
const Elephant = "/media/portfolio/elephant.jpg";
const Wings = "/media/portfolio/wings.jpg";
const Flower = "/media/portfolio/flower.jpg";
const Jelly = "/media/portfolio/jelly.jpg";
const Dress = "/media/portfolio/dress.jpg";

const Posts = [
  {
    imageUrl: [Scar],
    title: "The Lion King - Scar",
    description:
      "Scar : How are you even a 90's kid if you haven't watched The Lion King? I recall watching The Lion King everytime I visited my grandmother during my summer vacations. It is amazing how much fear this character instilled in me every single time, albeit the joy I experienced at the end of the movie was equally matched. Extremely apprehensive of quilling this iconic character, I was worried if I would be able to capture the 'villain' look. I was super ecstatic with the end result and this is now one of my prized possessions.",
  },
  {
    imageUrl: [Rooster],
    title: "The Rooster",
    description:
      "The Rooster was the first piece I worked on that was intricate and challenging. This piece marks the beginning of taking risks and self-confidence for me as an artist. Once I was done with this piece, I constantly felt like challenging myself each and every time to make even more intricate and complex pieces.",
  },
  {
    imageUrl: [Cat],
    title: "Catnap",
    description:
      "I recently saw a cat stretching all the way and it was evident, that the little one just woke up from a catnap! I couldn't help but admire the elegance with which they stretch and I knew then that I had to quill this. I barely had an outline, but as the piece began to take shape, I liked what it was starting to capture. One of my personal favourites, I present to you, my version of a cat stretch.",
  },
  {
    imageUrl: [Parrot],
    title: "Blue and Yellow Macaw",
    description:
      "The Blue and Yellow Macaw found in South America are popularly known due to their striking color, ability to talk, and the close bond they share with humans. This is my take on this flamboyant bird, inspired by Tayfun Tinmaz, an artist from Istanbul, who also works with paper and makes magic!",
  },
  {
    imageUrl: [Fish],
    title: "On Finding Nemo",
    description:
      "The most sought after fish following the movie, Finding Nemo, the clown fish was something that I wanted to quill after I saw it in person. Amongst the pristine blue waters of the Maldives, the Indian ocean is home to these timid cuties. They make themselves comfortable in corals and I was fortunate enough to spot a few of them.",
  },
  {
    imageUrl: [Lilly],
    title: "The Lily Branch",
    description:
      "Lilies - These large, fragrant flowers marked with spots and brush strokes, bloom in a wide range of colors. These late spring/summer bloomers remind you of what beauty and solace look like in symphony. Lilies are one of my favourite flowers and everytime I look at them, I'm reminded of home, my happy place!",
  },
  {
    imageUrl: [Deadpool],
    title: "Deadpool",
    description:
      "The Marvel Comics fan that I've always been, Deadpool was definitely on my list of characters to quill. I loved unconventional humour and quirky characters portrayed in the Deadpool movies.",
  },
  {
    imageUrl: [Joker],
    title: "The Joker",
    description:
      "A criminal mastermind and arguably the greatest supervillain of all time, I quilled this psychopathic character for a friend who is a huge fan of the series. I tried a different style of quilling for this piece and soon came to the realization that there is so much more to this form of art. Trying different things always leads to new possibilities, doesn't it?",
  },
  {
    imageUrl: [Mojojojo],
    title: "Mojo Jojo",
    description:
      "The archenemy to the Powerpuff Girls, and my personal favourite villain on the series, I have always been amused by this character. The oversized brain and all those varied colours to portray a super-intelligent modified chimpanzee, instantly got me hooked to the series. Quilling this guy was definitely a walk down memory lane for me.",
  },
  {
    imageUrl: [Leaf],
    title: "The Autumn Maple",
    description:
      "The Autumn Leaf - The spectacular phenomenon that turns bushels of green into an eye-catching palette of crisp yellow, orange, red and golden colors. Autumn leaves you in awe of mother nature and reminiscing memories from the year that passed by.",
  },
  {
    imageUrl: [Pumpkin],
    title: "Pumpkin Picking",
    description:
      "Pumpkin Patch - Nothing signifies Fall better than the Pumpkin harvest. The yearly traditions of travelling to the Pumpkin Patch, picking out a perfect orange and carving Jack-o-Lanterns are simple joys that every child should experience at least once.",
  },
  {
    imageUrl: [Elephant],
    title: "The White Elephant",
    description:
      "The White Elephant Gift Exchange! A party game where impractical, amusing gifts are exchanged during festivities. The goal of the game is to entertain party-goers rather than to gain genuinely sought after valuable gifts. It is said to originate from the historic practice of the King of Siam however, the first use of this term is still debatable. This is my version of the white elephant â€” a cute little gift that sparks a different kind of joy!",
  },
  {
    imageUrl: [Wings],
    title: "The Pride of India",
    description:
      "This one is special! A Country's Armed Forces are its pride. I have immense respect for all the brave souls who sacrificed their lives for their country. This specific piece are Naval Pilot's wings. My friend is a Naval Officer and I quilled this for him. Just like the Indian Air Force, the Indian Navy Air Arm (and Indian Army Aviation Corp) has many aviation/flight operation related patches. As a rule of thumb, the full spread wings with no letter mentioned in between is for a Pilot.",
  },
  {
    imageUrl: [Flower],
    title: "The Yellow Trumpet",
    description:
      "Spring is the season I look forward to every year. Majestic, vibrant yellow trumpets bloom and blossom throughout my city that signal the beginning of springtime. These trees bear the weight of these majestic wild flowers making every street look dreamy and beautiful.",
  },
  {
    imageUrl: [Jelly],
    title: "The Jellyfish",
    description:
      "Generally transparent, few of these beauties come in vibrant colours. This is my version of the Jellyfish, the way I imagine it in my head. Inspired by my visit to the S.E.A Aquarium, Singapore where I had the chance to see these creatures in full glory.",
  },
  {
    imageUrl: [Dress],
    title: "The Dress",
    description:
      "The Dress! The way you choose to dress influences your behavior and there has been sufficient evidence to support this claim. According to current research, clothing influences the degree of one's social power, perceptions of personality, and many other interpersonal behaviors. In the early decades of the 1900s, women began easing up on restrictive garments and shortening hemlines. The clothing a woman chooses to wear doesn't necessarily define who she is, what she wants, or what she's capable of - but it does influence how she feels. A sense of empowerment is instilled when you choose to dress well.",
  },
];

export default Posts;
