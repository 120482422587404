import styled from "styled-components";
import { BoldColor } from "../../constants/colors";

const Signature = styled.div`
  display: block;
  padding-left: 25px;
  margin-top: 15px;
  position: relative;
  font-size: 22px;
  color: ${BoldColor};

  &::before {
    content: "";
    background-color: ${BoldColor};
    width: 15px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export default Signature;
