import React from "react";
import "./assets/css/reset.css";
import "./assets/css/global.css";
import "./assets/css/carousel.css";
import "./assets/css/stylesheet.css";

import Cover from "./components/Cover";
import Overview from "./components/Overview";
import Portfolio from "./components/Portfolio";
import Footer from "./components/Footer";
// import Gallery from "./components/Gallery";
import Header from "./components/Header";
import CoverButton from "./components/CoverButton";

const cover = "/media/cover.jpg";

function App() {
  return (
    <>
      <Cover
        imageUrl={cover}
        title="PaperCut Kraft"
        subTitle="Quill it away"
        actionButton={<CoverButton />}
      />
      <Header heightOffset={92} />
      <Overview />
      <Portfolio />
      {/* <Gallery /> */}
      <Footer />
    </>
  );
}

export default App;
