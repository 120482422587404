import React from "react";
import styled from "styled-components";

import { LightBlack, LightGrey } from "../constants/colors";

export default function Footer() {
  return (
    <Container>
      Copyright © PaperCut Kraft {new Date().getFullYear()}.
    </Container>
  );
}

const Container = styled.div`
  font-size: 14px;
  height: 68px;
  border-top: 1px solid rgb(158, 158, 158);
  background-color: ${LightGrey};
  color: ${LightBlack};
  display: flex;
  justify-content: center;
  align-items: center;
`;
