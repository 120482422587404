export const LinkMap = [
  { title: "Home", to: "home", href: "/" },
  { title: "Overview", to: "overview", href: "/" },
  { title: "Portfolio", to: "story", href: "/" },
  {
    title: "Fundraiser 2021",
    to: "exhibit",
    href: "/covid-relief/fundraiser-2021",
  },
];
